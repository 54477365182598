<template>
  <v-list-item
    v-if="notificationsEnabled"
    prepend-icon="mdi-bell-ring"
    :disabled="isNotificationPermitted === 'granted'"
    :title="t('btn.add_notification')"
    @click="promptNotificationPermission"
  />
</template>

<script
  setup
  lang="ts"
>
import { useOneSignal } from '@onesignal/onesignal-vue3'
import { useToast } from 'vue-toastification'
import { useDisplay } from 'vuetify'
import { useNotificationsEnabled } from '~/composables/useNotificationsEnabled'

const {t} = useI18n()
const oneSignal = useOneSignal()
const toast = useToast()
const {mobile} = useDisplay()
const notificationsEnabled = useNotificationsEnabled()
const isNotificationPermitted = ref<NotificationPermission>(Notification.permission)

onMounted(() => {
  oneSignal.Notifications.addEventListener('permissionChange', () => {
    isNotificationPermitted.value = Notification.permission
  })
})
async function promptNotificationPermission() {
  await oneSignal.Notifications.requestPermission()
  if(isNotificationPermitted.value === 'denied') {
    toast.warning(mobile.value ? t('msg.denied_notification') + t('msg.restart_app') : t('msg.denied_notification'))
  }
}
</script>