<template>
  <div
    v-if="!mobile"
    class="d-flex"
  >
    <div v-for="item in items.filter(item => item.permission)">
      <v-btn
        size="small"
        :class="item === activeItem ? 'text-secondary' : ''"
        @click="navigateTo(`${item.to}`)"
      >
        {{ t(`btn.${item.name}`) }}
      </v-btn>
    </div>
  </div>

  <div
    v-if="mobile"
    class="d-flex w-100 px-2"
  >
    <div
      v-for="item in items.filter(item => item.permission)"
      class="width text-center"
    >
      <v-btn
        size="small"
        color="primary"
        variant="flat"
        :class="item === activeItem ? 'text-secondary' : ''"
        @click="navigateTo(`${item.to}`)"
      >
        {{ t(`btn.${item.name}`) }}
      </v-btn>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>

import { useDisplay } from 'vuetify'
import { useLodgingStore } from '~/store/lodging'
import { P } from '~/enums/permission'
import checkPermission from '~/utils/check-permissions'

const {t} = useI18n()
const route = useRoute()
const {mobile} = useDisplay()
const lodgingStore = useLodgingStore()
const userIsAdmin = useIsAdmin()

interface Item {
  name: string
  to: string
  permission?: boolean
}

const items = reactive<Item[]>([
  {name: 'tasks', to: `/tasks`, permission: checkPermission([P.TASKS_READ, P.TASKS_READ_OWN])},
  {name: 'users', to: `/users`, permission: checkPermission([P.USERS_READ, P.LODGINGS_WRITE_OWN_USERS])},
  {name: 'statistics', to: `/statistics`, permission: checkPermission([P.STATISTICS_READ, P.STATISTICS_READ_OWN])},
  {name: 'premises', to: `/lodgings/${lodgingStore.activeId}`, permission: checkPermission([P.PREMISES_WRITE, P.PREMISES_WRITE_OWN]) && !userIsAdmin},
  {name: 'lodgings', to: `/lodgings`, permission: checkPermission([P.LODGINGS_READ]) && userIsAdmin}
])

const activeItem = computed(() => {
  if (route.fullPath === '/') {
    return {name: 'tasks'}
  }
  return items.find((item: Item) => {
    return route.fullPath.toString().includes(item.to)
  })
})
</script>

<style scoped>
.width {
  width: 33.3%;
}
</style>

