<template>
  <v-badge
    :content="notificationsStore.unread"
    :class="hideBadge ? 'hide' : null"
    color="error"
  >
    <v-btn
      icon="mdi-bell"
      size="small"
      class="ms-2"
      @click="emit('click')"
    />
  </v-badge>
</template>

<script
  setup
  lang="ts"
>
import { useNotificationsStore } from '~/store/notifications'

const notificationsStore = useNotificationsStore()

const hideBadge = computed(() => {
  return !notificationsStore.unread
})

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<style
  scoped
  lang="scss"
>
.hide:deep(.v-badge__badge) {
  display: none !important;
}
</style>
