<template>
  <!-- APP BAR -->
  <ui-app-bar />
  <v-main>
    <slot />
  </v-main>
</template>

<script
  setup
  lang="ts"
>

</script>
