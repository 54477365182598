<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="!disabled ? props : null"
        :append-icon="!disabled ? 'mdi-chevron-down' : ''"
        :prepend-icon="mobile ? null : 'mdi-domain'"
        size="small"
        class="me-3"
      >
        <span
          v-if="!items.includes(route.fullPath)"
          :class="mobile ? 'd-inline-block text-truncate' : ''"
          :style="mobile ? 'max-width: 100px;' : ''"
        >
          {{ activeLodging?.name }}
        </span>
        <span v-else> {{ activeLodging?.name }} </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="lodging in user?.lodgings ?? []"
        :key="lodging.id"
        :title="lodging.name"
        @click="setActiveLodging(lodging)"
      />
    </v-list>
  </v-menu>
</template>

<script
  setup
  lang="ts"
>
import { useLodgingStore } from '~/store/lodging'
import { useDisplay } from 'vuetify'

const { data: user } = useAuth()
const store = useLodgingStore()
const { t } = useI18n()
const { mobile } = useDisplay()
const route = useRoute()

const items = [
  '/tasks',
  '/users',
  'statistics'
]

const activeLodging = computed({
  get: () => store.active,
  set: (value: Lodging | undefined) => {
    if (value) store.setActiveId(value.id)
  }
})

const disabled = computed(() => !!user.value?.lodgings ? (user.value.lodgings.length < 2) : true)

function setActiveLodging(lodging: Lodging) {
  // imposto resort attivo
  activeLodging.value = lodging
  // torno a home
  window.location.replace('/')
}
</script>
